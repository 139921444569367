<script></script>
<div class="policy">
    <h1>PRIVACY POLICY</h1>
    <p class="lead">Here at eRepublik Labs we care about your privacy and we respect your privacy rights.</p>
    <p class="lead">By using the eRepublik Service (as defined below), you permit the collection and use of your
        personal
        information as outlined below and you signify your agreement to this Privacy Policy. If you do not agree with
        these
        terms, you should not use the eRepublik Service.
    </p>
    <hr/>
    <h3>1 ABOUT OUR PRIVACY POLICY</h3>
    <p>eRepublik Labs Limited of 3rd Floor, Waterloo Exchange, Waterloo Road, Dublin 4, D04 E5W7, Ireland, registration
        no.
        462101, VAT No. IE - 9693023K (<strong>“eRepublik Labs”</strong>,<strong>“we”</strong>,<strong>“us”</strong> and
        <strong>“our”</strong>) provides and operates the <strong>World at War - WW2 Strategy MMO Game</strong>, including the
        http://worldatwarww2.com webpage and the Game iOS and Android Apps, all their sections (the “Game”) as well as
        the
        in-game profiles and any other areas of the Game not listed here, that will or may be created in the future
        (<strong>the “eRepublik Service”</strong>).
    <ul>
        <li><b class="bg-warning">1.1</b> The eRepublik Service allows people to register as players on the Game in
            order to
            engage and participate in a variety of activities and use the functionalities made available on the
            eRepublik
            Service from time to time.
        </li>
        <li><b class="bg-warning">1.2</b> This policy (together with our Terms and Conditions of Use <a
                href="https://www.worldatwarww2.com/terms-of-service">https://www.worldatwarww2.com/terms-of-service</a>
            and
            any other documents referred to in it) sets out the basis on which any personal data we collect from you, or
            that you provide to us, will be processed by us. Please read the following carefully to understand our views
            and
            practices regarding your personal data and how we will treat it.
        </li>
    </ul>
    <h3>2 HOW WE COLLECT INFORMATION</h3>
    <ul>
        <li><b class="bg-warning">2.1</b> We only collect and use personal information of our users insofar as this is
            necessary to provide the eRepublik Service. We collect and process personal information only with the user's
            consent or in those cases in which the collection and processing of the personal information is permitted by
            law. When registering as a user of the eRepublik Service or during your time as a user of the eRepublik
            Service
            you will be asked to provide information to us. We will inform you at the time of collecting other
            information
            from you whether you must provide the information to use the eRepublik Service or whether the provision of
            information requested by us is optional. We will inform you at the time of collecting information from you
            whether the information that you provide to us will be accessible to other users of the eRepublik Service or
            by
            third parties so that you can decide whether to provide the information to us.
        </li>
        <li>
            <b class="bg-warning">2.2</b> We may collect and process the following personal data about you:
            <ul>
                <li>email</li>
                <li>Google ID</li>
                <li>Facebook ID</li>
                <li>Facebook Email</li>
                <li>City & Country</li>
                <li>IP</li>
                <li>Mobile device related info, such as: imei (device id); Brand, Model and Carrier; Operating
                    System/Platform (iOS, Android) & OS version; Network type: WiFi/Mobile Data
                </li>
            </ul>
        </li>
        <li>
            <b class="bg-warning">2.2.1</b> if you contact us (including by email or post) we may keep a record of that
            correspondence and of the information contained in it;
        </li>
        <li>
            <b class="bg-warning">2.2.2</b> information that you submit in response to an in-game chat discussion,
            private
            messages or through any other in- game communication channels;
        </li>
        <li>
            <b class="bg-warning">2.2.3</b> the functionality that you use through the eRepublik Service, including your
            gameplay choices;
        </li>
        <li>
            <b class="bg-warning">2.2.4</b> details of financial transactions you carry out through the eRepublik
            Service;
            and
        </li>
        <li>
            <b class="bg-warning">2.2.5</b> details of your visits to the eRepublik Service including, but not limited
            to,
            traffic data, location data, weblogs and other communication data, whether this is required for our own
            billing
            purposes or otherwise and the resources that you access.
        </li>
        <li>
            <b class="bg-warning">2.3</b> Persons under 16 years of age are not entitled to register on the eRepublik
            Service. If you are between 16 and 18 years of age you agree that you have made sure that you have brought
            this
            Privacy Policy to the attention of a parent or guardian and that they have consented to you agreeing to this
            Privacy Policy and using the eRepublik Service (provided that the giving of such consent is not prohibited
            by
            law).
        </li>
    </ul>
    <h3>3 USES MADE OF THE INFORMATION</h3>
    <ul>
        <li>
            <b class="bg-warning">3.1</b> Our users may participate in the virtual community by interacting with other
            users
            on the eRepublik Service through discussion forums, alliances, in-game private messages or in-game chat
            channels. These can then be accessed and read by other users of the eRepublik Service. If you choose to be a
            registered user of the eRepublik Service, we will not display your personal information. Only in-game
            information will be available to other users, which is information that will allow users to evaluate your
            in-game activity and your eRepublik Service communications.
        </li>
        <li>
            <b class="bg-warning">3.2</b> We use information held about you in the following ways:
            <ul>
                <li>
                    <b class="bg-warning">3.2.1</b> to ensure that content on the eRepublik Service is presented in the
                    most
                    effective manner for you and for your computer or device; such as hosting and displaying
                    information,
                    providing updates, developing, improving or expanding the eRepublik Service and addressing technical
                    issues; The legal basis for the processing of personal information is Art. 6 para. 1 lit. b GDPR.
                </li>
                <li>
                    <b class="bg-warning">3.2.2</b> to provide you with information, products or services that you
                    request
                    from us. The legal basis for the processing of personal information is Art. 6 para. 1 lit. b GDPR.
                </li>
                <li>
                    <b class="bg-warning">3.2.3</b> to provide you with information which we believe may interest you
                    (including products or services of any of our Partners) in case you have consented to be contacted
                    for
                    such purposes including by interacting with advertising functionality in the eRepublik Service. The
                    legal basis for the processing of personal information is Art. 6 para. 1 lit. a GDPR. For our mobile
                    games we use advertising platforms that provide incentivized advertising (e.g. the user gets a
                    reward by
                    watching an ad). The legal basis for the processing of personal information is Art. 6 para. 1 lit. f
                    GDPR. It is in our interest to provide advertising in our games and to give the user an opportunity
                    to
                    receive rewards in the game.
                </li>
                <li>
                    <b class="bg-warning">3.2.4</b> to carry out our obligations arising from any contracts entered into
                    between you and us; to establish and maintain your account on the eRepublik Service and to protect
                    the
                    security of your account; The legal basis for the processing of personal information is Art. 6 para.
                    1
                    lit. b GDPR.
                </li>
                <li>
                    <b class="bg-warning">3.2.5</b> to deliver and maintain a high quality service; to provide customer
                    support or to resolve customer issues; The legal basis for the processing of personal information is
                    Art. 6 para. 1 lit. b GDPR.
                </li>
                <li>
                    <b class="bg-warning">3.2.6</b> to allow you to access the eRepublik Service and to participate in
                    interactive features of our service, when you choose to do so; The legal basis for the processing of
                    personal information is Art. 6 para. 1 lit. b GDPR.
                </li>
                <li>
                    <b class="bg-warning">3.2.7</b> to notify you about changes to our service, new features or new
                    versions
                    of the eRepublik Service, about new games or applications developed by our company or by our
                    Partners;
                    The legal basis for the processing of personal information is Art. 6 para. 1 lit. b GDPR and Art. 6
                    para. 1 lit. f GDPR. We have an interest in providing you with marketing information about our
                    products
                    and those of our partners.
                </li>
                <li>
                    <b class="bg-warning">3.2.8</b> for the purposes of compiling statistics and to generate and analyze
                    information about our users so we can better understand them and how they use the eRepublik Service
                    and
                    draw trends and predictions based on this information; The legal basis for the processing of
                    personal
                    information is Art. 6 para. 1 lit. f GDPR. Our interest is in improving our services.
                </li>
                <li>
                    <b class="bg-warning">3.2.9</b> to determine whether you are in compliance with the eRepublik
                    Service’s
                    rules and to impose sanctions where necessary; The legal basis for the processing of personal
                    information is Art. 6 para. 1 lit. f GDPR. Our interest is in improving our services and detecting
                    fraud.
                </li>
                <li>
                    <b class="bg-warning">3.2.10</b> to process payments, if the case. The legal basis for the
                    processing of
                    personal information is Art. 6 para. 1 lit. b GDPR.
                </li>
                <li>
                    <b class="bg-warning">3.2.11</b> if you signed up for any newsletter, to contact you by email for
                    news,
                    promotional purposes or market research and that you may opt-out of by clicking an opt-out link
                    located
                    in such an email. The legal basis for the processing of personal information, in the case of
                    existing
                    consent, Art. 6 para. 1 lit. a GDPR.
                </li>
            </ul>
        </li>
        <p>The personal information will be deleted as soon as it is no longer necessary to achieve the purpose for
            which it
            was collected. The user's email address will therefore be stored for as long as the subscription to the
            newsletter is active. The user may cancel the subscription to the newsletter at any time by clicking on the
            unsubscribe link at the end of each email newsletter.</p>
        <li><b class="bg-warning">3.4</b> No personally identifiable information will be exported to third party
            websites or
            Apps, other than with your express authorisation in response to a request from a third party user. Please
            note
            that any authorisation that you give to a third party user to use your data is a matter for you and a third
            party user; we are not responsible for the manner in which you choose to allow them use your data.
        </li>
        <li><b class="bg-warning">3.5</b> We may sell or licence the content you post on any area of eRepublik Service
            to
            personalize it and to identify/feature popular members. For example, we may promote the most popular active
            members. While we do not expect to transfer the complete member database to others, we use the nicknames and
            avatars in the database throughout our networks and to generate results for others, including, but not
            limited
            to, enabling users to contact each other and facilitating inter-user communications.
        </li>
    </ul>
    <h3>4 DISCLOSURE OF YOUR INFORMATION TO OTHER PEOPLE</h3>
    <ul>
        <li><b class="bg-warning">4.1</b> We do not disclose to any third party personal information that you provide to
            us
            unless we have your permission or the law permits or requires it.
        </li>
        <li><b class="bg-warning">4.2</b> If you provide personal information to us you understand and agree that we may
            disclose your personal information to the following third parties:
            <ul>
                <li>
                    <b class="bg-warning">4.2.1</b> to the eRepublik Labs hosting provider: Amazon Inc. which uses
                    servers
                    located in the EU and in the US.
                </li>
                <p>You acknowledge that we may change the hosting provider at any time and any such change will be
                    posted on
                    the eRepublik Service by an amendment to this Privacy Policy. You consent to the transfer of your
                    personal data to any new hosting provider’s servers. You may communicate any questions, comments or
                    requests in relation to any change in hosting provider by emailing privacy@erepubliklabs.com or via
                    the
                    Help & Support Menu available in-game.
                </p>
                <li>
                    <b class="bg-warning">4.2.2</b> if you have indicated at registration, or at any time during your
                    time
                    as a user of the eRepublik Service that you are happy to receive information or promotions from
                    selected
                    third parties about goods and services which may be of interest to you, you consent to us sending
                    you
                    such offers or information. You may opt out of receiving such offers or change your preferences at
                    any
                    time by emailing privacy@erepubliklabs.com;
                </li>
                <li>
                    <b class="bg-warning">4.2.3</b> to companies that provide emailing services, with the sole purpose
                    of
                    sending you information about our services, including about any updates, new features or new
                    versions of
                    the eRepublik Service. These companies are prohibited from using your personally identifiable
                    information for any other purpose than as mentioned in this article. You may opt out of receiving
                    such
                    offers or change your preferences at any time by emailing privacy@erepubliklabs.com;
                </li>
                <li>
                    <b class="bg-warning">4.2.4</b> to the prospective seller, buyer or assignee of any business or
                    assets
                    related to the eRepublik Service or all or part of eRepublik Labs Limited, ERPK LABS SRL, or any
                    other
                    member of our group of companies; and
                </li>
                <li>
                    <b class="bg-warning">4.2.5</b> to a third party if we are under a duty to disclose or share your
                    personal data with that third party in order to comply with any legal obligation, or in order to
                    enforce
                    or apply our terms and conditions of use <a href="https://www.worldatwarww2.com/terms-of-service">https://www.worldatwarww2.com/terms-of-service</a>
                    , or to protect the rights, property, or safety of our group of companies, eRepublik Labs, our
                    customers, other registered users or others. This includes exchanging information with third parties
                    for
                    the purposes of fraud protection and credit risk reduction.

                </li>
                <li>
                    <b class="bg-warning">4.2.6</b> to advertisers enabling them to display relevant advertising
                </li>
            </ul>
        </li>
        <li>
            <b class="bg-warning">4.3</b> We will notify you when information about you may be provided to third parties
            in
            ways other than explained above, and you will have the option to prevent such information sharing at the
            time
            that we notify you. We will not transfer such information without your consent.
        </li>
        <li>
            <b class="bg-warning">4.4</b> We do not disclose personal information about you to other third parties
            (including advertisers or marketing companies) except those set out in this policy, but we may provide them
            with
            aggregate anonymised information about the eRepublik Service users. For example, we may inform them that 500
            men
            aged under 30 have clicked on their advertisement displayed on the eRepublik Services on any given day, or
            we
            might inform third parties regarding the number of unique users who visit the eRepublik Service, the
            demographic
            breakdown of our community users of the eRepublik Service or the activities that visitors to the eRepublik
            Service engage in while on the eRepublik Service. We may also use such aggregate anonymised information to
            help
            advertisers reach the kind of audience they want to target (for example, women in the southeast of Ireland)
            when
            displaying advertisements on our website.
        </li>
        <li>
            <b class="bg-warning">4.5</b> It may be necessary to transfer your personal information outside of the
            European
            Economic Area (EEA). This may happen where our servers or suppliers and service providers are based outside
            of
            the EEA or where you use our services and products while visiting countries outside of the EEA. The data
            protection and other laws of these countries may not be as comprehensive as those in Ireland or the EU but
            in
            these instances we will take steps to ensure that your privacy rights are respected. You agree that we may
            transfer your personal data on that basis.
        </li>
    </ul>
    <h3>5 SECURITY AND CONTROL OF YOUR PERSONAL DATA</h3>
    <ul>
        <li>
            <b class="bg-warning">5.1</b> We will take all steps reasonably necessary to ensure that your data is
            treated
            securely and in accordance with this Privacy Policy.
        </li>
        <li>
            <b class="bg-warning">5.2</b> Where we have given you (or where you have chosen) a password which enables
            you to
            access certain parts of the eRepublik Service, you are responsible for keeping this password confidential.
            We
            ask you not to share a password with anyone. You are responsible for the security of your password and our
            Terms
            and Conditions of Use <a href="https://www.worldatwarww2.com/terms-of-service">https://www.worldatwarww2.com/terms-of-service</a>
            deal with this in more detail.
        </li>
        <li>
            <b class="bg-warning">5.3</b> Unfortunately, the transmission of information via the Internet is not
            completely
            secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your
            data transmitted to eRepublik Labs; any transmission is at your own risk. Once we have received your
            information, we will use strict procedures and security features to try to prevent unauthorised access. You
            acknowledge that we are not responsible for any intercepted information sent via the Internet, and you
            hereby
            release us from any and all claims arising out of or related to the use of intercepted information in any
            unauthorised manner.
        </li>
        <li>
            <b class="bg-warning">5.4</b> You should be aware that when you voluntarily disclose personally identifiable
            information and agree to make that information public on the eRepublik Service via In-game chat, personal
            messages, forums, or in other areas of the eRepublik Service, that information, along with any substantive
            information disclosed in your communication, can be collected, correlated and used by third parties. These
            third
            parties or other eRepublik Service users may use your information to send you unsolicited messages. Such
            activities are beyond our control. Please do not post any personal information on the eRepublik Service or
            in
            other areas that you wish to keep private.
        </li>
    </ul>
    <h3>
        6. Legal basis for the processing of personal information
    </h3>
    <p>Insofar as we obtain the consent of the data subject for the processing of personal information, Art. 6 para. 1
        lit.
        a GDPR serves as the legal basis.</p>
    <p>If the processing of personal information required for the performance of a contract to which the data subject is
        a
        party, Art. 6 para. 1 lit. b GDPR serves as the legal basis. This also applies to steps at the request of the
        data
        subject prior to entering into a contract.</p>
    <p>Insofar as the processing of personal information is processing is necessary for compliance with a legal
        obligation
        to which we are subject, Art. 6 para. 1 lit. c GDPR serves as the legal basis.</p>
    <p>In the event that processing of personal information is necessary in order to protect the vital interests of the
        data
        subject or of another natural person, Art. 6 para 1 lit. d GDPR serves as the legal basis.
    </p>
    <p>In the event that processing of personal information is necessary for the performance of a task carried out in
        the
        public interest or in the exercise of official authority vested in us, Art. 6 para 1 lit. e GDPR serves as the
        legal
        basis.
    </p>
    <p>If processing of personal information is necessary for the purposes of the legitimate interests pursued by us or
        by a
        third party, except where such interests are overridden by the interests or fundamental rights and freedoms of
        the
        data subject which require protection of personal information, in particular where the data subject is a child,
        Art.
        6 para. 1 lit. f GDPR serves as the legal basis for processing.
    </p>
    <h3>
        7. Links to third party websites
    </h3>
    <ul>
        <li>
            <b class="bg-warning">7.1</b> The eRepublik Service may, from time to time, contain links to and from the
            websites of our partner networks, advertisers and affiliates as well as third party websites to which we
            have no
            affiliation. If you follow a link to any of these websites, please note that these websites have their own
            privacy policies and terms of use and that we do not accept any responsibility or liability for these
            policies
            or for the activities on those websites.
        </li>
    </ul>
    <h3>
        8. Retention of personal information
    </h3>
    <p>The personal information will be deleted or access will be restricted as soon as the purpose of storage ceases to
        apply. The data will also be deleted or access will be restricted if a storage period expires, unless there is a
        need for further storage of the personal information for the conclusion or fulfilment of a contract.
    </p>
    <h3>
        9. Registration to the eRepublik Service via Facebook or other App Stores (Apple App Store, Google Play Store)
    </h3>
    <p>In order to use some of our eRepublik Service, you may register by providing personal information via Facebook,
        the
        Apple App Store and the Google Play Store. The data will not be passed on to third parties.
    </p>
    <p>The legal basis for the processing of personal information in the course of the registration is a contractual
        relation according to Art. 6 para. 1 lit. b GDPR.
    </p>
    <h3>
        10. Rights of the data subject
    </h3>
    <p>If your personal information is processed, you have the following rights vis-à-vis us. To invoke those rights,
        please
        send an email to privacy@erepubliklabs.com, specifying the type of request.
    </p>
    <p><b>a) Right of access</b></p>
    <p>You have the right to obtain from us a confirmation as to whether or not personal information concerning you are
        being processed, and, where that is the case, access to the personal data and the following information:</p>
    <ul>
        <li>
            <b class="bg-warning">(1)</b> the purposes of the processing;
        </li>
        <li>
            <b class="bg-warning">(2)</b> the categories of personal data concerned;
        </li>
        <li>
            <b class="bg-warning">(3)</b> the recipients or categories of recipient to whom the personal data have been
            or
            will be disclosed, in particular recipients in third countries or international organisations;
        </li>
        <li>
            <b class="bg-warning">(4)</b> where possible, the envisaged period for which the personal data will be
            stored,
            or, if not possible, the criteria used to determine that period;
        </li>
        <li>
            <b class="bg-warning">(5)</b> the existence of the right to request from us rectification or erasure of
            personal
            data or restriction of processing of personal information or to object to such processing;
        </li>
        <li>
            <b class="bg-warning">(6)</b> the right to lodge a complaint with a supervisory authority;
        </li>
        <li>
            <b class="bg-warning">(7)</b> where the personal data are not collected from you, any available information
            as
            to their source;
        </li>
        <li>
            <b class="bg-warning">(8)</b> the existence of automated decision-making, including profiling, referred to
            in
            Art. 22 para. 1 and 4 GDPR and, at least in those cases, meaningful information about the logic involved, as
            well as the significance and the envisaged consequences of such processing for you.
        </li>
    </ul>
    <p>You have the right to request information as to whether the personal data concerning you is transferred to a
        third
        country or to an international organization. In this context, you may request to be informed of the appropriate
        guarantees pursuant to Art. 46 GDPR in connection with the transmission.
    </p>
    <p><b>b) Right of rectification</b></p>
    <p>You have the right to obtain from us within undue delay the rectification of inaccurate or incomplete personal
        information. Taking into account the purposes of the processing, you shall have the right to have incomplete
        personal information completed, including by means of providing a supplementary statement.
    </p>
    <p><b>c) Right to erasure (‘right to be forgotten’)
    </b></p>
    <p>You shall have the right to obtain from us the erasure of personal information concerning without undue delay and
        we
        shall have the obligation to erase personal information without undue delay where one of the following grounds
        applies:</p>
    <ul>
        <li>
            <b class="bg-warning">(1)</b> the personal information is no longer necessary in relation to the purposes
            for
            which they were collected or otherwise processed;
        </li>
        <li>
            <b class="bg-warning">(2)</b> you withdraw consent on which the processing is based according to Art. 6
            para.1
            lit. a GDPR or Art. 9 para. 2 lit. a GDPR, and where there is no other legal ground for the processing;
        </li>
        <li>
            <b class="bg-warning">(3)</b> you object to the processing pursuant to Art. 21 para. 1 GDPR and there are no
            overriding legitimate grounds for the processing, or you object to the processing pursuant to Art. 21 para 2
            GDPR;
        </li>
        <li>
            <b class="bg-warning">(4)</b> the personal information has been unlawfully processed;
        </li>
        <li>
            <b class="bg-warning">(5)</b> the personal information has to be erased for compliance with a legal
            obligation
            in the European Union
        </li>
        <li>
            <b class="bg-warning">(6)</b> the personal information has been collected in relation to the offer of
            information society services referred to in Article 8 para.1.
        </li>
    </ul>
    <p>Where we have made the personal information public and is obliged pursuant to the above to erase the personal
        information, we, taking account of available technology and the cost of implementation, shall take reasonable
        steps,
        including technical measures, to inform controllers which are processing the personal data that the data subject
        has
        requested the erasure by such controllers of any links to, or copy or replication of, those personal data.</p>
    <p>The right to erasure shall not apply to the extent that processing is necessary:</p>
    <ul>
        <li>
            <b class="bg-warning">(1)</b> for exercising the right of freedom of expression and information;
        </li>
        <li>
            <b class="bg-warning">(2)</b> for compliance with a legal obligation which requires processing by the
            European
            Union or for the performance of a task carried out in the public interest
        <li>
            <b class="bg-warning">(3)</b> for the establishment, exercise or defense of legal claims.
        </li>
    </ul>
    <p><b>d) Right to restriction of processing
    </b></p>
    <p>You shall have the right to obtain from us the restriction of processing where one of the following applies:</p>
    <ul>
        <li>
            <b class="bg-warning">(1)</b> the accuracy of the personal data is contested by yourself, for a period
            enabling
            us to verify the accuracy of the personal data;
        </li>
        <li>
            <b class="bg-warning">(2)</b> the processing is unlawful and the data subject opposes the erasure of the
            personal information and requests the restriction of their use instead;
        </li>
        <li>
            <b class="bg-warning">(3)</b> we no longer needs the personal data for the purposes of the processing, but
            they
            are required by the data subject for the establishment, exercise or defense of legal claims;
        </li>
        <li>
            <b class="bg-warning">(4)</b> You have objected to processing pursuant to Art. 21 para. 1 GDPR pending the
            verification whether the legitimate grounds of us override those of the data subject.
        </li>
    </ul>
    <p>Where processing has been restricted, such personal data shall, with the exception of storage, only be processed
        with
        your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights
        of
        another natural or legal person or for reasons of important public interest of the European Union or of a Member
        State.
    </p>
    <p>If you have obtained restriction of processing pursuant to the above, you shall be informed by us before the
        restriction of processing is lifted.
    </p>
    <p><b>e) Right to data portability
    </b></p>
    <p>You have the right to receive the personal information, which you have provided to us, in a structured, commonly
        used
        and machine-readable format and have the right to transmit those data to another controller without hindrance
        from
        the controller to which the personal information have been provided, where:
    </p>
    <ul>
        <li>
            <b class="bg-warning">(1)</b> the processing is based on consent pursuant to Art. 6 para 1 lit. a or Art. 6
            para
            1 lit. b or Art. 2 para 2 lit. a
        </li>
        <li>
            <b class="bg-warning">(2)</b> the processing is carried out by automated means.
        </li>
    </ul>
    <p>The right shall not adversely affect the rights and freedoms of others.</p>
    <p>In exercising your right to data portability you shall have the right to have the personal information
        transmitted
        directly from one controller to another, where technically feasible.</p>
    <p>The exercise of this right shall be without prejudice to the right of erasure. That right shall not apply to
        processing necessary for the performance of a task carried out in the public interest or in the exercise of
        official
        authority vested in the controller.</p>
    <p><b>f) Notification regarding rectification or erasure of personal data or restriction of processing</b></p>
    <p>We shall communicate any rectification or erasure of personal data or restriction of processing carried to each
        recipient to whom the personal data have been disclosed, unless this proves impossible or involves
        disproportionate
        effort. We will inform you about those recipients if you request it.
    </p>
    <p><b>g) Right to object</b></p>
    <p>You shall have the right to object, on grounds relating to your particular situation, at any time to processing
        of
        personal data concerning him or her which is based on Art. 6 para. 1 lit e) or lit. f). We shall no longer
        process
        the personal information unless it demonstrates compelling legitimate grounds for the processing which override
        the
        interests, rights and freedoms of the data subject or for the establishment, exercise or defense of legal
        claims.</p>
    <p>Where personal information is processed for direct marketing purposes, you shall have the right to object at any
        time
        to processing of your personal data for such marketing, which includes profiling to the extent that it is
        related to
        such direct marketing.
    </p>
    <p>Where you object to processing for direct marketing purposes, the personal information shall no longer be
        processed
        for such purposes.
    </p>
    <p>At the latest at the time of the first communication with you, the right referred to above shall be explicitly
        brought to your attention and shall be presented clearly and separately from any other information.
    </p>
    <p>In the context of the use of information society services, and notwithstanding Directive 2002/58/EC, you may
        exercise
        his or her right to object by automated means using technical specifications.
    </p>
    <p><b>h) Automated individual decision-making
    </b></p>
    <p>The you shall have the right not to be subject to a decision based solely on automated processing, including
        profiling, which produces legal effects or similarly significantly affects you
    </p>
    <p>This shall not apply if the decision:
    </p>
    <ul>
        <li>
            <b class="bg-warning">(a)</b> is necessary for entering into, or performance of, a contract between you and
            us
        </li>
        <li>
            <b class="bg-warning">(b)</b> is authorised by European Union law and which also lays down suitable measures
            to
            safeguard your rights and freedoms and legitimate interests; or
        </li>
        <li>
            <b class="bg-warning">(b)</b> is based on your explicit consent.
        </li>
    </ul>
    <p>TWe shall implement suitable measures to safeguard your rights and freedoms and legitimate interests, at least
        the
        right to obtain human intervention on the part of us, to express his or her point of view and to contest the
        decision.
    </p>
    <p>Decisions shall not be based on special categories of personal data unless Art. 9 para.2 lit. a) or lit g) GDPR
        applies and suitable measures to safeguard your rights and freedoms and legitimate interests are in place.
    </p>
    <p><b>i) Right of complain to a supervisory authority</b></p>
    <p>Without prejudice to any other administrative or judicial remedy, you have the right of appeal to a supervisory
        authority, in particular in the European Member State where you reside, work or suspect of infringement, if you
        believe that the processing of personal information concerning you is not in compliance with GDPR. The
        supervisory
        authority to which the complaint has been lodged shall inform the complainant of the status and results of the
        complaint, including the possibility of a judicial remedy under Art. 78 GDPR.</p>
    <h3>
        11. IP addresses and cookies
    </h3>
    <ul>
        <li>
            <b class="bg-warning">11.1</b> If you access the eRepublik Service from a mobile device, we may collect
            information about your device including (where available) your IP address, device alphanumeric IDs,
            operating
            system (iOS, Android, OS version) and browser type, device ID, brand and model, information regarding the
            used
            carrier, network type (Wifi/Mobile data), volume and status of data transmission, click-through statistics,
            the
            name of the domain and host from which you access the Internet, the date and time you accesses portions of
            the
            eRepublik Service.
        </li>
        <li>
            <b class="bg-warning">11.3</b> Tracking technologies enable us
        </li>
        <li>
            <b class="bg-warning">11.3.1</b> to estimate our audience size and usage pattern;
        </li>
        <li>
            <b class="bg-warning">11.3.2</b> to store information about your preferences, and so allow us to customise
            eRepublik Service according to your individual interests; and
        </li>
        <li>
            <b class="bg-warning">11.3.3</b> thto speed up your use of the eRepublik Service and searches.
        </li>
    </ul>
    <h3>
        12. Access to information and updating, verifying and deleting personal data
    </h3>
    <ul>
        <li>
            <b class="bg-warning">12.1</b> At any time, you can make a written request to have access to all the
            personal
            information that we hold about you. We will provide this information to you and may request a small
            administrative charge.
        </li>
        <li>
            <b class="bg-warning">12.2</b> As a registered user of the eRepublik Service, You can modify and update your
            personal information by contacting us via the Support Centre available in-game or at
            privacy@erepubliklabs.com.
            If you choose to delete your account, please contact us at <a href="mailto:privacy@erepubliklabs.com">privacy@erepubliklabs.com</a>
            and request that your account be deleted.However, your username, avatar, bases, other in-game actions,
            elements
            or posts on the in-game chats shall remain recorded in the Game History. The only information that will
            accompany such posts will be your Username and avatar.
        </li>
    </ul>
    <h3>
        13. Changes to our Privacy Policy
    </h3>
    <ul>
        <li>
            <b class="bg-warning">13.1</b> Any changes we may make to our Privacy Policy in the future will be posted on
            this page and, where appropriate, notified to you by email. You should visit this page periodically to
            review
            any changes to the Privacy Policy.
        </li>
    </ul>
    <h3>
        14. User feedback and complaints, data protection officer
    </h3>
    <ul>
        <li>
            <b class="bg-warning">14.1</b> Questions, comments and requests regarding this Privacy Policy are welcomed
            and
            should be addressed to <a href="mailto:privacy@erepubliklabs.com">privacy@erepubliklabs.com</a> or via the
            Help
            & Support Menu available in-game.
        </li>
        <li>
            <b class="bg-warning">14.2</b> Our data protection officer can be contacted at <a
                href="mailto:dpo@stillfront.com">dpo@stillfront.com</a>.

        </li>
    </ul>
</div>
<style lang="scss">
  @import './styles/vars.scss';

  .policy {
    padding: 20px;

    ul {
      list-style-type: none;
    }
  }
</style>